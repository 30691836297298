import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const clbGrowthAPI = createAsyncThunk(
    'clbs-growth',
    async (data, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/clbs/growth`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'clbs-growth',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(clbGrowthAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(clbGrowthAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(clbGrowthAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const clbGrowthReducer = counterSlice.reducer

export default clbGrowthReducer

