import {
  Autocomplete,
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asigneToNGOAPI, asigneToOrgAPI } from "../../redux/slb/asigneToOrg";
import { getAllSlbsAPI } from "../../redux/slb/getAllSlbs";

import { getAllOrgAPI } from "../../redux/orgnisation/getAllOrg";

function AsignSLB({ open, setOpen, asignData }) {
  const getAllOrgSelector = useSelector((state) => state?.getAllOrg);
  const { result } = getAllOrgSelector;

  const asigneToOrgSelector = useSelector((state) => state?.asigneToOrg);
  const { message } = asigneToOrgSelector;

  const dispatch = useDispatch();

  const [ngoDetails, setNgoDetails] = useState();

  const handleAsignToOrg = async () => {
    await dispatch(asigneToOrgAPI({ CLBIds: asignData, OrgId: ngoDetails }));
    await dispatch(getAllSlbsAPI());
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getAllOrgAPI());
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <Stack spacing={2}>
              <Typography variant="h6">Assign to an Organization!</Typography>
              <Autocomplete
                disablePortal
                options={result}
                getOptionLabel={(option) => option.name}
                fullWidth
                onChange={(e, value) => {
                  setNgoDetails(value?._id);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Organization" />
                )}
              />

              <Stack direction="row" spacing={2}>
                <Button onClick={handleAsignToOrg} variant="contained">
                  Assign
                </Button>
                <Button onClick={handleClose} variant="contained" color="error">
                  Close
                </Button>
              </Stack>
              {/* <Button onClick={handleAsignToOrg} variant="contained">
                Assign
              </Button> */}
            </Stack>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default AsignSLB;
