const sampleCSV = [
  {
    files: "",
    title: "",
    link: "",
    description: "",
    inr: "",
    usd: "",
    category: "",
    collect: "",
    type: "",
    status: "",
    country: "",
    active: "",
    isBidAble: "",
    startDate: "",
    endDate: "",
    asinged: "",
    royaltyFeePercentage: "",
    creator: "",
  }
];

export default sampleCSV




