import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }


export const addBlogAPI = createAsyncThunk(
    'add-Blogs',
    async(data, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`/blogs/create`,data)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



const counterSlice = createSlice({
    name: 'add-Blogs',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(addBlogAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addBlogAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(addBlogAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})


const addBlogAPIReducer = counterSlice.reducer

export default addBlogAPIReducer