import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios"


const initialState = { loading:false, result: [], error: null, message: ""};

export const getAllBlogsCategoryAPI = createAsyncThunk(
    'get-All-Blog-Category',
    async(data, { rejectWithValue}) => {
        try {
            const response = await Axios.get(`/blogsCategory/all`);
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }

)



const counterSlice = createSlice({
    name:'get-All-Blog-Category',
    initialState,
    extraReducers: (builder) => {
        builder
             .addCase(getAllBlogsCategoryAPI.pending, (state, action) => {
                state.loading = true
             })
             .addCase(getAllBlogsCategoryAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action?.payload?.message
             })
             .addCase(getAllBlogsCategoryAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
             })
    }
})


const getAllBlogsCategoryReducer =  counterSlice.reducer

export default getAllBlogsCategoryReducer