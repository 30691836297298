import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Grid,
  Button,
  CircularProgress,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { default as React, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { BsFilterLeft } from "react-icons/bs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useDispatch, useSelector } from "react-redux";
import HeadingBar from "../../components/common/HeadingBar";
import PopperButton from "../../components/common/Popper";
import { getAllUsersAPI } from "../../redux/users/getAllUsers";
import { userService } from "../../services/user.service";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import AddUser from "./AddUser";

function Users(props) {
  const getAllUsersSelector = useSelector((state) => state?.getAllUsers);
  const { result, loading } = getAllUsersSelector;
  const dispatch = useDispatch();

  const [attributes, setAttributes] = useState({
    totalUsers: 0,
    activeUsers: 0,
    inActiveUsers: 0,
    clbCreators: 0,
  });
  const [sortList, setSortList] = useState([
    "Newly Registered",
    "Alphabetically",
  ]);
  const [activeSort, setActiveSort] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const [addUser, setAddUser] = useState(false);

  const [csvData, setCsvData] = useState([]);

  let columns = [
    {
      field: "_id",
      headerName: "User",
      width: 300,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography sx={{ my: 1 }} variant="subtitle2">
          {params?.row?._id}
        </Typography>
      ),
    },
    // {
    //   field: "role",
    //   headerName: "Role",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "userName",
    //   headerName: "User Name",
    //   width: 100,
    //   headerClassName: "super-app-theme--header",
    // },
    // Temp: To be removed later
    {
      field: "name",
      headerName: "Name",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography sx={{ my: 1 }} variant="subtitle2">
          {params?.row?.name}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography sx={{ my: 1 }} variant="subtitle2">
          {params?.row?.email}
        </Typography>
      ),
    },
    {
      field: "mobile",
      headerName: "Phone Number",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography sx={{ my: 1 }} variant="subtitle2">
          {params?.row?.mobile}
        </Typography>
      ),
    },
    // {
    //   field: "country",
    //   headerName: "Country",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "proofAddress",
    //   headerName: "Address Proof",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => {
    //     return params?.row?.bankStatementsCopy ? (
    //       <img src={params?.row?.bankStatementsCopy} alt="bank statement" />
    //     ) : (
    //       <Typography sx={{ my: 1 }} variant="subtitle2" color="error">
    //         No Copy Updated
    //       </Typography>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const apiData = async () => {
      const { data } = await userService.getUserAttributes();
      // console.log({data})
      await setAttributes({ ...data.result });
    };
    apiData();
  }, []);

  useEffect(() => {
    dispatch(getAllUsersAPI({ sort: sortList[activeSort] }));
  }, [dispatch, sortList, activeSort]);

  useEffect(() => {
    const users = [];
    result.map((item) => {
      const { password, ...user } = item;
      users.push(user);
    });
    setCsvData(users);
  }, [result]);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <HeadingBar
          title="Users"
          element={
            <div className="w-full gap-4 justify-end mx-2 h-full flex">
              <PopperButton
                buttonContent={
                  <div className=" flex gap-3 items-center py-2 px-4 rounded-lg font-bold">
                    Sort <BsFilterLeft className="text-2xl" />
                  </div>
                }
                popperBody={
                  <div className="flex flex-col text-sm font-thin relative w-48 p-4 gap-2 bg-white">
                    {sortList.map((sort, i) => (
                      <button
                        className={`text-start ${
                          activeSort === i ? "text-green-600 font-bold" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveSort(i);
                        }}
                      >
                        {sort}
                      </button>
                    ))}
                  </div>
                }
                buttonClassName=""
                popperClassName=""
              />
              <Button variant="outlined" onClick={() => setAddUser(true)}>
                Add User
              </Button>
              <CSVLink data={csvData} filename={`users-${new Date()}.csv`}>
                <Button variant="outlined">
                  Download Users Data
                  <CloudDownloadIcon sx={{ ml: 1 }} />
                </Button>
              </CSVLink>
            </div>
          }
        />

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#D9EDD4" }}>
              <Typography variant="h4" fontWeight={600}>
                {attributes.totalUsers || "--"}
              </Typography>
              <Typography>Total User Registered</Typography>
            </Paper>
          </Grid>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#F6DEDD" }}>
              <Typography variant="h4" fontWeight={600}>
                {attributes.activeUsers || "--"}
              </Typography>
              <Typography>Active Users</Typography>
            </Paper>
          </Grid>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#DDDFF2" }}>
              <Typography variant="h4" fontWeight={600}>
                {attributes.inActiveUsers || "--"}
              </Typography>
              <Typography>InActive Users</Typography>
            </Paper>
          </Grid>
          <Grid item lg={3}>
            <Paper sx={{ p: 2, bgcolor: "#F0D9F3" }}>
              <Typography variant="h4" fontWeight={600}>
                {attributes.clbCreators || "--"}
              </Typography>
              <Typography>CLBs Creators</Typography>
            </Paper>
          </Grid>
        </Grid>

        <Paper sx={{ height: 550, width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>

      {addUser && <AddUser open={addUser} setOpen={setAddUser} />}
    </>
  );
}

export default Users;
