import React, { useEffect, useState } from "react";
import SideBar from "../../ui/SideBar";
import Body from "../../ui/Body";
import Appbar from "../../ui/Appbar";
import { CircularProgress, IconButton, Paper, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBlog from "./AddBlog";
import { getAllBlogsAPI } from "../../redux/Blog/getAllBlogs";
import { deleteBlogAPI } from "../../redux/Blog/deleteBlogs";
import EditBlog from "./EditBlog";
import EditIcon from "@mui/icons-material/Edit";




const Blog = () => {
  const getAllBlogsSelector = useSelector((state) => state?.getAllBlogs );
  const { result, loading} = getAllBlogsSelector;
  

  const [openEdit, setOpenEdit] = useState(false);
  const [blog, setBlog] = useState({})

  const dispatch = useDispatch();

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 300,
      headerClassName: "super-app-theme--header"
    },
    {
      field: "description",
      headerName: "Description",
      width: 600,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "edit_category",
      headerName: "Edit",
      width: 150,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handleEditBlog(params?.row)}
            >
               <EditIcon/>
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete_category",
      headerName: "Delete",
      width: 150,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handleDeleteCategory(params?.row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ]



   const handleDeleteCategory = async (blogId) =>{

     await dispatch(deleteBlogAPI(blogId))
     await dispatch(getAllBlogsAPI())
   } 
   

   const handleEditBlog = (blog) =>{
     setOpenEdit(true)
     setBlog(blog)
   }


   useEffect(()=>{
     dispatch(getAllBlogsAPI())
   },[])


  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="p">Blogs</Typography>
            </Stack>
            <Stack spacing={2} direction="row" alignItems="center">
              <AddBlog/>
            </Stack>
          </Stack>
        </Paper>


        <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
      </Body>

      { openEdit ? <EditBlog open={openEdit} blog={blog} setOpen={setOpenEdit}/> : null}
    </>
  );
};

export default Blog;
