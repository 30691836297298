import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { editAdminAPI } from "../../redux/admin/editAdmin";
import { getAllAdminsAPI } from "../../redux/admin/getAllAdmins";

const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required(),
    email: yup.string().email("Invalid email address").required(),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required(),
    mobile: yup
      .string()
      .matches(/^\d{10}$/, "Mobile number should have only 10 digits")
      .required("Mobile number is required"),
  })
  .required();

export default function EditAdmin({ open, setOpen, existingData }) {
  const addAdminSelector = useSelector((state) => state?.addAdmin);
  const { message, error } = addAdminSelector;
  console.log(existingData);
  const dispatch = useDispatch();

  const [banner, setBanner] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEditAdmin = async (data) => {
    dispatch(editAdminAPI(data));
    dispatch(getAllAdminsAPI());
    setBanner("");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setBanner("");
  };

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      name: existingData?.name,
      email: existingData?.email,
      mobile: existingData?.mobile,
      password: existingData?.password,
      userId: existingData?._id,
    };
    reset({ ...defaultValues });
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <form onSubmit={handleSubmit(handleEditAdmin)}>
            <Card sx={{ p: 2, width: 600 }}>
              <Stack spacing={2}>
                <Typography variant="h6">Edit Admin</Typography>
                <TextField
                  multiline
                  label="Name *"
                  {...register("name")}
                  error={errors?.name?.message}
                  helperText={errors?.name?.message}
                />
                <TextField
                  multiline
                  label="Mobile Number *"
                  {...register("mobile")}
                  error={errors?.mobile?.message}
                  helperText={errors?.mobile?.message}
                />
                <TextField
                  multiline
                  label="Email *"
                  {...register("email")}
                  error={errors?.email?.message}
                  helperText={errors?.email?.message}
                />
                <TextField
                  multiline
                  label="Password *"
                  {...register("password")}
                  error={errors?.password?.message}
                  helperText={errors?.password?.message}
                />

                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Edit
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </form>
        </Box>
      </Modal>
    </>
  );
}
