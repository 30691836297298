import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios"


const initialState = { loading:false, result: [], error: null, message: ""};

export const getSingleBlogAPI = createAsyncThunk(
    'get-Single-Blog',
    async(data, { rejectWithValue}) => {
        try {
            const response = await Axios.get(`/blogs/single-blog/${data}`);
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }

)



const counterSlice = createSlice({
    name:'get-Single-Blog',
    initialState,
    extraReducers: (builder) => {
        builder
             .addCase(getSingleBlogAPI.pending, (state, action) => {
                state.loading = true
             })
             .addCase(getSingleBlogAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action?.payload?.message
             })
             .addCase(getSingleBlogAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
             })
    }
})


const getSingleBlogAPIReducer =  counterSlice.reducer

export default getSingleBlogAPIReducer