import React from "react";
import { HiOutlineFilter } from "react-icons/hi";
import HeadingBar from "../../components/common/HeadingBar";
import { useLocation } from "react-router-dom";
import { ngoService } from "../../services/ngo.service";
import SideBar from "../../ui/SideBar";
import Body from "../../ui/Body";
import Appbar from "../../ui/Appbar";

function OrganizationDetails(props) {
  const { state } = useLocation();
  const handleChangeStatus = async (e, status) => {
    e.preventDefault();
    e.stopPropagation();
    if (status === "Approved") {
      await ngoService.changeStatus(state._id, "Approved");
    } else if (status === "Rejected") {
      await ngoService.changeStatus(state._id, "Rejected");
    }
  };
  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <HeadingBar
          title="Ngo"
          element={
            <div className="w-full gap-4 justify-end mx-2 h-full flex">
              <button className=" flex gap-3 items-center py-2 px-4 rounded-lg font-bold">
                Filter <HiOutlineFilter className="text-2xl" />
              </button>
              <button className="primary-btn flex gap-3 items-center py-2 px-4 rounded-lg font-bold">
                Asign
              </button>
            </div>
          }
        />
        <div className="w-full text-[#999999] p-8 gap-8 flex flex-col bg-white-background p-2 rounded-lg shadow-lg h-fit">
          <div className="flex flex-col gap-2">
            <h5 className="text-[#35ABAF]">
              You have recieved an approval request from {state.name} NGO
            </h5>
            <h5>Location: {state.location}</h5>
            <h5>No. of courses: {state.noOfCourses}</h5>
            <h5>No. of students: {state.noOfStudents}</h5>
            <h5 className="font-bold text-[#666666]">
              SLBs Required: {state.noOfSlbs}
            </h5>
          </div>
          <div className="text-[#000000]/60">{state.description}</div>
          {state.status === "Pending" && (
            <div className="flex gap-4 justify-start">
              <button
                className="secondary-btn flex gap-3 items-center w-48 py-2 flex justify-center items-center  rounded-lg font-bold"
                onClick={(e) => handleChangeStatus(e, "Rejected")}
              >
                <h5>Reject</h5>
              </button>
              <button
                className="primary-btn flex gap-3 items-center py-2 w-48 flex justify-center items-center rounded-lg font-bold"
                onClick={(e) => handleChangeStatus(e, "Approved")}
              >
                <h5>Approve</h5>
              </button>
            </div>
          )}
        </div>
      </Body>
    </>
  );
}

export default OrganizationDetails;
