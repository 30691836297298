import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, Card, FormHelperText, Modal, Stack, TextField, Typography } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getAdminsPermissionAPI } from '../../redux/config/getAdminsPermission';
import { editPermissionModuleAPI } from '../../redux/permissions/editPermissionModule';
import { getAllPermissionsAPI } from "../../redux/permissions/getAllPermissions";
import { getPermissionModuleDetailsAPI } from '../../redux/permissions/getPermissionModuleDetails';


const schema = yup.object({
    name: yup.string().required("Name is required"),
}).required();


function EditPermission({ open, setOpen, permissionId }) {
    const getAdminsPermissionSelector = useSelector(state => state?.getAdminsPermission)
    const { result } = getAdminsPermissionSelector

    const getPermissionModuleDetailsSelector = useSelector(state => state?.getPermissionModuleDetails)
    const { result: selectedPermission } = getPermissionModuleDetailsSelector

    const editPermissionModuleSelector = useSelector(state => state?.editPermissionModule)
    const { message, error } = editPermissionModuleSelector

    const dispatch = useDispatch()

    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });


    const [permissions, setPermissions] = useState([])


    const handleEditNewPermissionModule = async (data) => {
        if (!permissions?.length) {
            return setError('permssionsError', { type: 'custom', message: 'Permission can not be empty!' });
        }
        await dispatch(editPermissionModuleAPI({ permissionId, permissions, name: data?.name }))
        await dispatch(getAllPermissionsAPI())
        setOpen(false)

    }

    const handleClose = () => setOpen(false)

    useEffect(() => {
        dispatch(getPermissionModuleDetailsAPI(permissionId))
        dispatch(getAdminsPermissionAPI())
    }, [dispatch]);


    useEffect(() => {
        let defaultValues = {};
        defaultValues = {
            name: result?.name
        }
        reset({ ...defaultValues });
    }, [result])


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal'>
                    <Card sx={{ p: 2, width: 600 }}>
                        <form onSubmit={handleSubmit(handleEditNewPermissionModule)}>
                            <Stack spacing={2}>
                                <Typography variant='h6'>Edit New Permission Module</Typography>
                                <TextField label='Permission Name *' {...register('name')} error={errors?.name?.message} helperText={errors?.name?.message} value={selectedPermission?.name} />
                                <Autocomplete
                                    multiple
                                    disablePortal
                                    defaultValue={selectedPermission?.permissions}
                                    options={result?.adminPermissions}
                                    fullWidth
                                    onChange={(e, value) => setPermissions(value)}
                                    renderInput={(params) => <TextField {...params} label="Select Permissions" />}
                                />
                                <FormHelperText error={errors?.permssionsError?.message}>{errors?.permssionsError?.message}</FormHelperText>
                                <Stack direction='row' spacing={2}>
                                    <Button type='submit' variant="contained">Edit</Button>
                                    <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

export default EditPermission;

