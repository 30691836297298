import {
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminsAPI } from "../../redux/admin/getAllAdmins";
import Axios from "../../config/authAxios";
import toast from "react-hot-toast";

const DeleteAdmin = ({ open, setOpen, existingData }) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      const { data } = await Axios.delete(`/admin/${existingData._id}`);
      console.log(data);
      await dispatch(getAllAdminsAPI({ sort: "All" }));
      setOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <Stack spacing={2}>
              <Typography variant="h6">Delete Admin</Typography>
              <Typography variant="">
                Do you want to Delete the Admin?
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleDelete}
                  color="error"
                >
                  Delete
                </Button>
                <Button onClick={() => setOpen(false)} variant="contained">
                  Close
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAdmin;
