import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getAdminsPermissionAPI } from "../../redux/config/getAdminsPermission";
import { createPermissionModuleAPI } from "../../redux/permissions/createPermissionModule";
import { getAllPermissionsAPI } from "../../redux/permissions/getAllPermissions";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    permissions: yup.array().min(1, "Permission cannot be empty!").required(),
  })
  .required();

function AddPermission({ open, setOpen }) {
  const getAdminsPermissionSelector = useSelector(
    (state) => state?.getAdminsPermission
  );
  const { result } = getAdminsPermissionSelector;
  const createPermissionModuleSelector = useSelector(
    (state) => state?.createPermissionModule
  );
  const { message, error } = createPermissionModuleSelector;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [permissions, setPermissions] = useState([]);

  const handleAddNewPermissionModule = async (data) => {
    // alert(data?.name);
    const formData = { ...data, permissions: data.permissions || [] };

    if (!/^[a-zA-Z ]*$/.test(data?.name)) {
      return setError("name", {
        type: "custom",
        message: "Permission Name should be text / alphabets only!",
      });
    }

    dispatch(createPermissionModuleAPI(formData));
    dispatch(getAllPermissionsAPI());
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getAdminsPermissionAPI());
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <form onSubmit={handleSubmit(handleAddNewPermissionModule)}>
              <Stack spacing={2}>
                <Typography variant="h6">
                  Create New Permission Module
                </Typography>
                <TextField
                  label="Permission Name *"
                  {...register("name")}
                  error={errors?.name?.message}
                  helperText={errors?.name?.message}
                />
                <Controller
                  name="permissions"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      disablePortal
                      options={result?.adminPermissions}
                      fullWidth
                      onChange={(e, value) => field.onChange(value)} 
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Permissions*"
                          error={!!errors?.permissions}
                        />
                      )}
                    />
                  )}
                />
                {errors?.permissions && (
                  <FormHelperText error style={{ marginLeft: '0.8rem' }}>
                    {errors.permissions.message}
                  </FormHelperText>
                )}
                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Create
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default AddPermission;
