import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, Button, Card, FormHelperText, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import fileUploader from '../../services/upload.service';
import { addBannerAPI } from '../../redux/banner/addBanner';
import { getAllBannersAPI } from '../../redux/banner/getAllBanners';

function AddBanner({ open, setOpen }) {
  const addBannerSelector = useSelector(state => state?.addBanner);
  const { message, error } = addBannerSelector;

  const dispatch = useDispatch();
  const [banner, setBanner] = useState('');

  const { handleSubmit, setError, formState: { errors }, reset } = useForm({});

  const handleFile = async ({ target }) => {
    const files = target.files?.[0];
    const s3Files = await fileUploader.single(files);
    setBanner(s3Files?.data?.fileUrl);
  }

  const handleAddNewBanner = async () => {
    if (!banner) {
      return setError('bannerError', { type: 'custom', message: 'Banner can not be empty!' });
    }
    await dispatch(addBannerAPI({ banner }));
    await dispatch(getAllBannersAPI({ banner }));

    setOpen(false);
    setBanner('');
  }

  const handleClose = () => {
    setOpen(false);
    setBanner('');
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal" sx={{ overflow: 'auto', maxHeight: '100vh' }}>
        <Card sx={{ p: 2, width: 600, margin: 'auto', mt: 5, overflow: 'hidden' }}>
          <Stack spacing={2}>
            <Typography variant='h6'>Add New Banner</Typography>
            <input
              accept=".jpg, .png, .glb, .mp4, .avif"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleFile}
            />
            <label htmlFor="raised-button-file">
              <Button variant="outlined" component="span" fullWidth>
                Upload Assets<CloudDownloadIcon sx={{ ml: 1 }} />
              </Button>
            </label>
            <FormHelperText error={errors?.bannerError?.message}>{errors?.bannerError?.message}</FormHelperText>

            <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
              {banner && <img src={banner} alt='bannerImg' style={{ maxWidth: '100%', maxHeight: '300px' }} />}
            </Box>

            <Stack direction='row' spacing={2}>
              <Button onClick={handleAddNewBanner} type='submit' variant="contained">Create</Button>
              <Button onClick={handleClose} variant="contained" color="error">Close</Button>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Modal>
  );
}

export default AddBanner;
