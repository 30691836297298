import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function Appbar() {
  const getCurrentAdminSelector = useSelector(state => state?.getCurrentAdmin)
  const { result } = getCurrentAdminSelector

  return (
    <>
      <Stack direction='row' spacing={1} sx={{ my: 2 }} justifyContent='flex-end'>
        <AccountCircleIcon />
        <Typography fontWeight={700}>{result?.name?.toUpperCase()}</Typography>
      </Stack>
    </>
  );
}
