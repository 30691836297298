import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const totalTransectionAPI = createAsyncThunk(
    'total-transection',
    async (data, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/payments/total-transection`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'total-transection',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(totalTransectionAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(totalTransectionAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(totalTransectionAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const totalTransectionReducer = counterSlice.reducer

export default totalTransectionReducer

