import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { createAboutUsAPI } from "../../redux/resource/createAboutUs";
import { getResourcesAPI } from '../../redux/resource/getResources';
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import Snack from '../../ui/snack';


const aboutUsSchema = yup.object({
  whoWe: yup.object({
    title: yup.string().required("required field"),
    desc: yup.string().required("required field"),
  }),
  whyUs: yup.object({
    title: yup.string().required("required field"),
    desc: yup.string().required("required field"),
  }),
  whatWeDo: yup.object({
    title: yup.string().required("required field"),
    desc: yup.string().required("required field")
  })
}).required();


export default function AboutUs() {
  const getResourcesSelector = useSelector(state => state?.getResources)
  const { result } = getResourcesSelector
  const createAboutUsSelector = useSelector(state => state?.createAboutUs)
  const { message } = createAboutUsSelector
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(aboutUsSchema),
  });
  const dispatch = useDispatch()


  const [snack, setSnack] = useState(false)

  const handleAddAboutUS = async (data) => {
    await dispatch(createAboutUsAPI(data))

  }

  useEffect(() => {
    dispatch(getResourcesAPI())
  }, [])

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      whoWe: {
        title: result?.anoutUs?.whoWe?.title,
        desc: result?.anoutUs?.whoWe?.desc,
      },
      whyUs: {
        title: result?.anoutUs?.whyUs?.title,
        desc: result?.anoutUs?.whyUs?.desc,
      },
      whatWeDo: {
        title: result?.anoutUs?.whatWeDo?.title,
        desc: result?.anoutUs?.whatWeDo?.desc,
      }
    }
    reset({ ...defaultValues });
  }, [result])


  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form onSubmit={handleSubmit(handleAddAboutUS)}>
            <Stack spacing={2}>
              <Stack direction='row' alignItems='center'>
                <Typography>About Us</Typography>
              </Stack>

              <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                <Typography sx={{ my: 2 }}>Who we are</Typography>
                <Stack spacing={2}>
                  <TextField size="small" label="Title" {...register('whoWe.title')} error={errors?.whoWe?.title?.message} helperText={errors?.whoWe?.title?.message} />
                  <TextField multiline size="small" label="Description" {...register('whoWe.desc')} error={errors?.whoWe?.desc?.message} helperText={errors?.whoWe?.desc?.message} />
                </Stack>
              </Paper>

              <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                <Typography sx={{ my: 2 }}>Services</Typography>
                <Stack spacing={2}>
                  <TextField size="small" label="Title" {...register('whyUs.title')} error={errors?.whyUs?.title?.message} helperText={errors?.whyUs?.title?.message} />
                  <TextField multiline size="small" label="Description" {...register('whyUs.desc')} error={errors?.whyUs?.desc?.message} helperText={errors?.whyUs?.desc?.message} />
                </Stack>
              </Paper>

              <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                <Typography sx={{ my: 2 }}>Our work</Typography>
                <Stack spacing={2}>
                  <TextField size="small" label="Title" {...register('whatWeDo.title')} error={errors?.whatWeDo?.title?.message} helperText={errors?.whatWeDo?.title?.message} />
                  <TextField multiline size="small" label="Description" {...register('whatWeDo.desc')} error={errors?.whatWeDo?.desc?.message} helperText={errors?.whatWeDo?.desc?.message} />
                </Stack>
              </Paper>
              <Box><Button type="submit" variant="contained">Update About Us</Button></Box>
            </Stack>
          </form>
        </Paper>
      </Body>
    </>
  );
}
