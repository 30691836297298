import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const getClbBidDetailsAPI = createAsyncThunk(
    'get-clb-bid-details',
    async (data, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/bids/getAllBid/${data}`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const getClbBidDetailsSlice = createSlice({
    name: 'get-clb-bid-details',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getClbBidDetailsAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getClbBidDetailsAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(getClbBidDetailsAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const getClbBidDetailsReducer = getClbBidDetailsSlice.reducer

export default getClbBidDetailsReducer

