import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

function EditTicker({ open, setOpen, data, editFun }) {
  const [editedTicker, setEditedTicker] = useState(data.ticker);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    editFun(editedTicker, data._id);
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600, borderRadius:3 }}>
            <form onSubmit={handleEdit}>
              <Stack spacing={2}>
                <Typography variant="h6">Update Ticker</Typography>
                <TextField
                  label="Ticker"
                  value={editedTicker}
                  onChange={(e) => setEditedTicker(e.target.value)}
                  fullWidth
                  InputProps={{ readOnly: false }}
                />
                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Save Ticker
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default EditTicker;
