import { Box, Button, Card, Modal, Stack, Typography } from "@mui/material";
import { default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delGallImgAPI } from "../../redux/gallery/delGallImg";
import { getAllGalleryImgsAPI } from "../../redux/gallery/getAllGalleryImgs";



export default function DeleteGallery({ open, setOpen, galleryId }) {
    const delGallImgSelector = useSelector(state => state?.delGallImg)
    const { message, error } = delGallImgSelector

    const dispatch = useDispatch()



    const handleDeleteCategory = async () => {
        await dispatch(delGallImgAPI(galleryId))
        await dispatch(getAllGalleryImgsAPI())
        setOpen(false)

    }

    const handleClose = () => setOpen(false)


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal'>
                    <Card sx={{ p: 2, width: 600 }}>

                        <Stack spacing={2}>
                            <Typography variant='h6'>Delete Gallery!</Typography>

                            <Stack direction='row' spacing={2}>
                                <Button onClick={handleDeleteCategory} type='submit' variant="contained">Delete</Button>
                                <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                            </Stack>
                        </Stack>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

