import React, { useState } from 'react';
import { usePopper } from 'react-popper';

const PopperButton = ({ buttonClassName, buttonContent, popperClassName, popperBody }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" className={buttonClassName} ref={setReferenceElement} onClick={() => setOpen(!open)}>
        {buttonContent}
      </button>

      {open && <div className={`z-50 shadow-lg left-7  ${popperClassName}`} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {popperBody}
        <div ref={setArrowElement} style={styles.arrow} />
      </div>}
    </>
  );
};

export default PopperButton