const { default: Axios } = require("../config/authAxios")

class NgoService {
    getAllVerifiedUsers = ({ status }) => Axios.get(`/ngo?status=${status}`);
    changeStatus = (id, status) => Axios.patch("/ngo/status/change/" + id + `?status=${status}`);
    assignSlb = (id, noOfSlbs) => Axios.patch("/ngo/assign/slbs/" + id, { noOfSlbs });
}

export const ngoService = new NgoService()

