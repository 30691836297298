import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { Box, Button, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { default as React, useEffect, useState } from 'react'
import Appbar from '../../ui/Appbar'
import Body from '../../ui/Body'
import SideBar from '../../ui/SideBar'
import { useDispatch, useSelector } from "react-redux"
import ReplyFeedback from "./ReplyFeedback"
import { getAllFeedbacksAPI } from "../../redux/feeback/getAllFeedbacks"
import { handleDate } from "../../helpers/date.helper"
import DoneIcon from '@mui/icons-material/Done';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function Feedback() {
  const getAllFeedbacksSelector = useSelector(state => state?.getAllFeedbacks)
  const { result, loading } = getAllFeedbacksSelector

  const [replyNow, setReplyNow] = useState(false)
  const [feedback, setFeedback] = useState(false)

  const dispatch = useDispatch()

  const handleReplyFeedback = (feeback) => {
    setReplyNow(true)
    setFeedback(feeback)
  }

  let columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "feedback",
      headerName: "Feedback",
      width: 250,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "reply",
      headerName: "Reply Now",
      width: 250,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <>
            {params?.row?.reply
              ? <Button sx={{ my: 2 }} variant='contained' endIcon={<DoneIcon />}>Replied</Button>
              : <Button sx={{ my: 2 }} onClick={() => handleReplyFeedback(params?.row)} variant='contained' endIcon={<ArrowRightAltIcon />}>Reply Now</Button>
            }


          </>
        )
      }
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 250,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return <Typography>{handleDate(params?.row?.created_at)}</Typography>
      }
    },
  ];


  useEffect(() => {
    dispatch(getAllFeedbacksAPI())
  }, [])

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Stack direction='row' alignItems='center'>
              <Typography variant="p">Feedback</Typography>
            </Stack>
          </Box>
        </Paper>


        <Paper
          sx={{ height: '80%', width: '100%' }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => 'auto'}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>
      {replyNow ? <ReplyFeedback open={replyNow} setOpen={setReplyNow} feedback={feedback} /> : null}
    </>
  )
}
