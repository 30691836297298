import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { genrateCLBDetailsAPI } from '../../redux/clb/genrateCLBDetails';
import { mintCLBAPI } from '../../redux/clb/mintCLB';
import Snack from '../../ui/snack';
import { getAllCLBsAPI } from '../../redux/clb/getAllCLBs';
import { getCLbDetailsAPI } from '../../redux/clb/getCLbDetails';

export default function ConfimeMintCLB({ open, setOpen, CLB, sortFilter, filters, searchTxt }) {
    const mintCLBSelector = useSelector(state => state?.mintCLB)
    const { message, error } = mintCLBSelector
    const dispatch = useDispatch()


    const handleBuyCLB = async () => {

        if (localStorage.getItem("NFT_ADDRESS")||CLB?.nft?.address) {
           let mintStatus =  await dispatch(mintCLBAPI({
                url: CLB?.files?.[0],
                CLBId: CLB?._id
            }))

            await dispatch(getAllCLBsAPI({ ...filters, sortFilters: sortFilter, title:searchTxt }));
        }
        setOpen(false)

    }

    const handleAddGeneration = async () =>{
        if(!CLB?.nft?.address){
        const genCLB = await dispatch(genrateCLBDetailsAPI({ CLBId: CLB?._id }))
        if (!genCLB?.payload?.error) {
           localStorage.setItem("NFT_ADDRESS",genCLB.payload.result?.nft?.address)
           await dispatch(getAllCLBsAPI({ ...filters, sortFilters: sortFilter, title:searchTxt }));
        }
    }

       
        setOpen(false)

    }

    const handleClose = () => setOpen(false);

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <Box className='confirmeBox'>
                    <DialogTitle variant='h4' color='primary'>Hey Wait!</DialogTitle>
                    <DialogContent sx={{ my: 2 }}>
                        <DialogContentText > Are you sure you want to Mint this CLB!</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={handleAddGeneration} autoFocus>Generate NFT Address</Button>
                        <Button variant='contained' onClick={handleBuyCLB} autoFocus>Mint</Button>
                        <Button color='error' onClick={handleClose}>Back</Button>
                    </DialogActions>
                </Box>
            </Dialog >
        </>

    );
}