import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Menu,
  Stack,
  Typography,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminsAPI } from "../../redux/admin/getAllAdmins";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import PopperButton from "../../components/common/Popper";
import AddAdmin from "./AddAdmin";
import AddAdminPermission from "./AddAdminPermission";
import DoneIcon from "@mui/icons-material/Done";
import { blockUnblockAdminAPI } from "../../redux/admin/blockUnblockAdmin";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BsFilterLeft } from "react-icons/bs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditAdmin from "./EditAdmin";
import DeleteAdmin from "./DeleteAdmin";

function AdminUsers() {
  const getAllAdminsSelector = useSelector((state) => state?.getAllAdmins);
  const { result, loading } = getAllAdminsSelector;

  const blockUnblockAdminSelector = useSelector(
    (state) => state?.blockUnblockAdmin
  );
  const { message, error } = blockUnblockAdminSelector;

  const dispatch = useDispatch();

  const [addPermission, setAddpermission] = useState(false);
  const [adminId, setaAdminId] = useState();
  const [addAdmin, setAddAdmin] = useState(false);
  const [snack, setSnack] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  const [existingData, setExistingData] = useState({});
  const [deleteAdmin, setDeleteAdmin] = useState(false);

  const [sortList, setSortList] = useState([
    "Newly Registered",
    "Alphabetically",
  ]);
  const [activeSort, setActiveSort] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  // Filter States
  const [filters, setFilters] = useState("All");

  const [anchorEl, setAnchorEl] = useState(null);
  const openFilter = Boolean(anchorEl);
  const [anchorElSort, setAnchorElSort] = useState(null);

  let columns = [
    {
      field: "_id",
      headerName: "Id",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.type == "Individual" && (
              <Typography variant="p" color="primary">
                Individual
              </Typography>
            )}
            {params?.row?.type == "Organization" && (
              <Typography variant="p" sx={{ color: "#025464" }}>
                Organization
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "permission_name",
      headerName: "Permission Module",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">{params?.row?.permissionId?.name}</Typography>
        );
      },
    },

    {
      field: "permissions",
      headerName: "Permissions",
      width: 300,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box>
              {params?.row?.permissionId?.permissions?.map((item, idx) => {
                return (
                  <Chip
                    label={item}
                    sx={{ my: 1, mx: 1 }}
                    key={idx}
                    variant="outlined"
                    color="primary"
                  />
                );
              })}
            </Box>
          </>
        );
      },
    },
    {
      field: "Add Permission",
      headerName: "Add Permission",
      width: 250,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleAddPermission(params?.row?._id)}
            sx={{ my: 2 }}
            color="info"
            endIcon={<ArrowRightAltIcon />}
          >
            Add Permission
          </Button>
        );
      },
    },
    {
      field: "block",
      headerName: "Block",
      width: 250,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.block ? (
              <Button
                variant="contained"
                onClick={() => handleBlockUnblock(false, params?.row?._id)}
                sx={{ my: 2 }}
                color="success"
                endIcon={<DoneIcon />}
              >
                Block
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleBlockUnblock(true, params?.row?._id)}
                sx={{ my: 2 }}
                color="info"
                endIcon={<ArrowRightAltIcon />}
              >
                Block
              </Button>
            )}
          </>
        );
      },
    },
    {
      field: "edit_user",
      headerName: "Edit User",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              sx={{ my: 1 }}
              onClick={() => {
                setEditAdmin(true);
                setExistingData(params?.row);
              }}
              color="info"
              endIcon={<EditIcon />}
            >
              Edit
            </Button>
          </>
        );
      },
    },
    {
      field: "delete_user",
      headerName: "Delete User",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => {
                setDeleteAdmin(true);
                setExistingData(params?.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleFilterToggle = (event) => setAnchorEl(event.currentTarget);
  const handleCloseFilter = () => setAnchorEl(null);
  const handleFilterToggleSort = (event) =>
    setAnchorElSort(event.currentTarget);
  const handleCloseFilterSort = () => setAnchorElSort(null);

  const handleFilterOptions = (e) => {
    setFilters(e.target.value);
  };

  const handleBlockUnblock = async (block, adminId) => {
    await dispatch(blockUnblockAdminAPI({ block, adminId }));
    await dispatch(getAllAdminsAPI({ sort: sortList[activeSort] }));
  };

  const handleAddPermission = (adminId) => {
    // dispatch(getSingleAdminAPI(adminId));
    setaAdminId(adminId);
    setAddpermission(true);
  };

  useEffect(() => {
    dispatch(getAllAdminsAPI());
  }, [dispatch]);

  useEffect(() => {
    if (filters === "All")
      dispatch(getAllAdminsAPI({ sort: sortList[activeSort] }));
    else
      dispatch(getAllAdminsAPI({ sort: sortList[activeSort], type: filters }));
  }, [dispatch, filters, sortList, activeSort]);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />

        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="p">Admins</Typography>
            </Stack>
            <Box sx={{ display: "flex" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  onClick={handleFilterToggle}
                  className="whiteBtn"
                  endIcon={<FilterAltIcon />}
                >
                  Filter
                </Button>

                <PopperButton
                  buttonContent={
                    <div className=" flex gap-3 items-center py-2 px-4 rounded-lg font-bold">
                      Sort <BsFilterLeft className="text-2xl" />
                    </div>
                  }
                  popperBody={
                    <div className="flex flex-col text-sm font-thin relative w-48 p-4 gap-2 bg-white">
                      {sortList.map((sort, i) => (
                        <button
                          className={`text-start ${
                            activeSort === i ? "text-green-600 font-bold" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveSort(i);
                          }}
                        >
                          {sort}
                        </button>
                      ))}
                    </div>
                  }
                  buttonClassName=""
                  popperClassName=""
                />

                <Button variant="outlined" onClick={() => setAddAdmin(true)}>
                  Add New Admin
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Paper>

        <Paper sx={{ height: "80%", width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>
      {addPermission && (
        <AddAdminPermission
          open={addPermission}
          setOpen={setAddpermission}
          adminId={adminId}
        />
      )}
      {addAdmin && <AddAdmin open={addAdmin} setOpen={setAddAdmin} />}
      {editAdmin && (
        <EditAdmin
          open={editAdmin}
          setOpen={setEditAdmin}
          existingData={existingData}
        />
      )}
      {deleteAdmin && (
        <DeleteAdmin
          open={deleteAdmin}
          setOpen={setDeleteAdmin}
          existingData={existingData}
        />
      )}

      {/* Filter menu */}
      <Menu
        anchorEl={anchorEl}
        open={openFilter}
        onClose={handleCloseFilter}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Stack spacing={1}>
            <Typography>Type</Typography>
            <RadioGroup onChange={handleFilterOptions}>
              <FormControlLabel
                checked={filters === "All"}
                value="All"
                control={<Radio />}
                label="All"
              />
              <FormControlLabel
                value="Individual"
                control={<Radio />}
                label="Individual"
              />
              <FormControlLabel
                value="Organization"
                control={<Radio />}
                label="Organization"
              />
            </RadioGroup>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
}

export default AdminUsers;
