import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Modal, Stack, TextField, Typography } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { editNotificationAPI } from '../../redux/notification/editNotification';
import { getAllAdminNotificationAPI } from '../../redux/notification/getAllAdminNotification';
import { singleNotificationAPI } from '../../redux/notification/singleNotification';



const schema = yup.object({
    message: yup.string().required("required field"),
}).required();


export default function EditNotification({ open, setOpen, notifyId }) {
    const singleNotificationSelector = useSelector(state => state?.singleNotification)
    const { result } = singleNotificationSelector

    const editBannerSelector = useSelector(state => state?.editBanner)
    const { message, error } = editBannerSelector

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch()


    const [notify, setNotify] = useState('')

    const handleEditNotification = async (data) => {
        await dispatch(editNotificationAPI({ ...data, notifyId }))
        await dispatch(getAllAdminNotificationAPI())

        setOpen(false)
    }

    const handleClose = () => setOpen(false)

    useEffect(() => {
        dispatch(singleNotificationAPI(notifyId))
    }, [dispatch])

    useEffect(() => {
        let defaultValues = {};
        defaultValues = {
            message: result?.message
        }
        reset({ ...defaultValues });
    }, [dispatch, result])



    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal'>
                    <Card sx={{ p: 2, width: 600 }}>
                        <form onSubmit={handleSubmit(handleEditNotification)}>
                            <Stack spacing={2}>
                                <Typography variant='h6'>Edit Notifiation!</Typography>
                                <TextField label='Message' {...register('message')} error={errors?.message?.message} helperText={errors?.message?.message} />
                                <Stack direction='row' spacing={2}>
                                    <Button type='submit' variant="contained">Edit</Button>
                                    <Button onClick={handleClose} variant="contained" color="error">Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}

