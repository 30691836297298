import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { addNotificationAPI } from '../../redux/notification/addNotification';
import Snack from '../../ui/snack';
import { getAllAdminNotificationAPI } from '../../redux/notification/getAllAdminNotification';

const schema = yup.object({
    message: yup.string().required(),
}).required();

export default function CreateNotification({ open, setOpen }) {

    const addNotificationSelector = useSelector(state => state?.addNotification)
    const { message, error } = addNotificationSelector

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });

    const dispatch = useDispatch()



    const handleClose = () => setOpen(false)

    const handleCreateNewNotification = async (data) => {
        await dispatch(addNotificationAPI(data))
        await dispatch(getAllAdminNotificationAPI())

        setOpen(false)
        reset()
    }


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box className='modal'>
                    <Card sx={{ p: 3, width: 500 }}>
                        <form onSubmit={handleSubmit(handleCreateNewNotification)}>
                            <Stack spacing={3}>
                                <Typography variant='h6'>Create Notification</Typography>
                                <TextField multiline label='Message' {...register('message')} error={errors?.message?.message} helperText={errors?.message?.message} />
                                <Stack direction='row' spacing={2}>
                                    <Button type='submit' variant='contained' color='primary'>Create</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Back</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal >
        </>
    )
}
