import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addOrganizationAPI } from "../../redux/orgnisation/addOrg";
import { getAllOrgAPI } from "../../redux/orgnisation/getAllOrg";
import { getAllAdminsAPI } from "../../redux/admin/getAllAdmins";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    location: yup.string().required("Location is required"),
    description: yup.string().required("Description is required"),
    slbRequired: yup.number().required().typeError("No. of SLB is required"),
    userId: yup.string().required("User is required"),  
  })
  .required();

export default function AddOrganization({ open, setOpen }) {
  const getAllAdminsSelector = useSelector((state) => state?.getAllAdmins);
  const { result, loading } = getAllAdminsSelector;

  const dispatch = useDispatch();

  const [banner, setBanner] = useState("");
  const [error, setError] = useState("");

  // console.log(userId);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddOrganization = async (data) => {
    await dispatch(addOrganizationAPI(data));
    setOpen(false)
    await dispatch(getAllOrgAPI());
    setBanner("");
  };

  const handleClose = () => {
    setError("");
    setOpen(false);
    setBanner("");
  };

  useEffect(() => {
    dispatch(
      getAllAdminsAPI({ sort: "Newly Registered", type: "Organization" })
    );
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <form onSubmit={handleSubmit(handleAddOrganization)}>
            <Card sx={{ p: 2, width: 600 }}>
              <Stack spacing={2}>
                <Typography variant="h6">Add Organization</Typography>
                <TextField
                  multiline
                  label="Name *"
                  {...register("name")}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                />
                <TextField
                  multiline
                  label="Location *"
                  {...register("location")}
                  error={Boolean(errors?.location)}
                  helperText={errors?.location?.message}
                />
                <TextField
                  multiline
                  label="Description *"
                  {...register("description")}
                  error={Boolean(errors?.description)}
                  helperText={errors?.description?.message}
                />
                <TextField
                  multiline
                  label="SLBs Required *"
                  {...register("slbRequired")}
                  error={Boolean(errors?.slbRequired)}
                  helperText={errors?.slbRequired?.message}
                />

                <Controller
                  name="userId"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      fullWidth
                      id="combo-box-demo"
                      options={result.map((usr) => ({
                        label: usr.name,
                        value: usr._id,
                      }))}
                      onChange={(e, value) => field.onChange(value ? value.value : "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add User *"
                          error={Boolean(errors?.userId)}
                          helperText={errors?.userId?.message}
                        />
                      )}
                    />
                  )}
                />

                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Create
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </form>
        </Box>
      </Modal>
    </>
  );
}
