import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, CircularProgress, IconButton, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { default as React, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import HeadingBar from "../../components/common/HeadingBar";
import { getAllCategoryAPI } from "../../redux/category/getAllCategory";
import { categoryService } from "../../services/category.service";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import DeleteCategory from "../banner/DeleteBanner";
import EditCategory from "./EditCategory";

function Category() {
  const getAllCategorySelector = useSelector((state) => state?.getAllCategory);
  const { result, loading } = getAllCategorySelector;

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [states, setStates] = useState({
    title: "",
  });
  const [errors, setErrors] = useState({
    title: "",
  });
  const [editCat, setEditCat] = useState(false);
  const [delCat, setDelCat] = useState(false);
  const [catId, setCatId] = useState("");

  let columns = [
    {
      field: "_id",
      headerName: "Category Id",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "title",
      headerName: "Title",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "edit_category",
      headerName: "Edit Category",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="info"
              onClick={() => handleEditCategory(params?.row?._id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete_category",
      headerName: "Delete Category",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handleDeleteCategory(params?.row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    // console.log("WOKRING",states)
    if (states.title.trim() === "") {
      setErrors({ ...errors, title: "Title is required" });
      return;
    }
    if (selectedIndex >= 0) {
      await categoryService.edit(data[selectedIndex]._id, { ...states });
    } else {
      await categoryService.create({ ...states });
    }
    setStates({ title: "" });
    setSelectedIndex(-1);
    setShowModal(!showModal);

    await dispatch(getAllCategoryAPI());
  };

  const handleChange = (e) => {
    const { currentTarget: input } = e;
    const data = { ...states };
    data[input.name] = input.value;
    setStates({ ...data });
  };

  const handleEditCategory = async (catId) => {
    setCatId(catId);
    setEditCat(true);
  };

  const handleDeleteCategory = async (catId) => {
    setCatId(catId);
    setDelCat(true);
  };

  useEffect(() => {
    dispatch(getAllCategoryAPI());
  }, []);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        {showModal && (
          <form
            className="h-full w-full inset-0 flex justify-center shadow-xl  items-center fixed bg-black/50 z-50"
            onSubmit={(e) => handleCreateCategory(e)}
          >
            <div className="bg-white flex flex-col p-4 w-96 h-fit rounded-lg">
              <div className="flex w-full justify-end text-2xl text-[#999999] ">
                <RxCross2
                  className="cursor-pointer"
                  onClick={() => {
                    setStates({ title: "" });
                    setErrors({ title: "" });
                    setShowModal(false);
                  }}
                />
              </div>
              {/* <ImageUploader image={files} onChange={imageHandler} /> */}
              <div className="py-2 h-fit">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={states.title}
                  onChange={handleChange}
                  className="p-2 rounded-md border border-solid w-full h-full"
                  placeholder="Title"
                />
              </div>
              <span style={{ fontSize: "10px", color: "red" }}>
                {errors.title}
              </span>
              <Button type="submit">Save</Button>
            </div>
          </form>
        )}
        <HeadingBar
          title="Category"
          element={
            <div className="w-full gap-4 justify-end mx-2 h-full flex">
              <button
                onClick={() => setShowModal(!showModal)}
                className="primary-btn flex gap-3 items-center py-2 px-4 rounded-lg font-bold"
              >
                Add
              </button>
            </div>
          }
        />

        <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>

      {editCat && (
        <EditCategory
          open={editCat}
          setOpen={setEditCat}
          id={catId}
          source="category"
        />
      )}
      {delCat && (
        <DeleteCategory
          open={delCat}
          setOpen={setDelCat}
          id={catId}
          source="category"
        />
      )}
    </>
  );
}

export default Category;
