import { yupResolver } from "@hookform/resolvers/yup";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Box,
  Button,
  Card,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";

import { default as React, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addGallImgAPI } from "../../redux/gallery/addGallImg";
import { getAllGalleryImgsAPI } from "../../redux/gallery/getAllGalleryImgs";
import fileUploader from "../../services/upload.service";

const schema = yup
  .object({
    description: yup.string().required(),
  })
  .required();

export default function AddGallery({ open, setOpen }) {
  const addGallImgSelector = useSelector((state) => state?.addGallImg);
  const { message, error } = addGallImgSelector;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  const [gallImg, setGallImg] = useState("");

  const handleFile = async ({ target }) => {
    const files = target.files?.[0];
    const s3Files = await fileUploader.single(files);
    setGallImg(s3Files?.data?.fileUrl);
  };

  const handleAddGallery = async (data) => {
    if (!gallImg) {
      return setError("gallError", {
        type: "custom",
        message: "Gallery image can not be empty!",
      });
    }
    await dispatch(addGallImgAPI({ ...data, image: gallImg }));
    await dispatch(getAllGalleryImgsAPI());

    setOpen(false);
    setGallImg("");
  };

  const handleClose = () => {
    setOpen(false);
    setGallImg("");
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal"  >
          <form onSubmit={handleSubmit(handleAddGallery)}>
            <Card sx={{ p: 2, width: 600, }}  >
              <Stack spacing={2}>
                <Typography variant="h6">Add New Gallery Image</Typography>
                <input
                  accept=".jpg, .png, .glb, .mp4, .avif"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFile}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="outlined" component="span" fullWidth>
                    Upload Gallery Image
                    <CloudDownloadIcon sx={{ ml: 1 }} />
                  </Button>
                </label>
                <FormHelperText error={errors?.gallError?.message}>
                  {errors?.gallError?.message}
                </FormHelperText>

                <Box>
                  {gallImg && <img src={gallImg} alt="gallery image" style={{maxHeight:"15rem"}} />}
                </Box>

                {/* <textarea label='Description' placeholder=''  name="w3review" rows="4" style={{width:"100%"}}>At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.</textarea>          */}
                {/* <TextField label='Description' {...register('description')} error={errors?.description?.message} helperText={errors?.description?.message} multiline /> */}
                <TextareaAutosize
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    padding: "1rem",
                  }}
                  minRows={4}
                  placeholder="Description"
                  label="Description"
                  {...register("description")}
                  error={errors?.description?.message}
                  helperText={errors?.description?.message}
                  multiline
                />

                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    Create
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </form>
        </Box>
      </Modal>
    </>
  );
}
