import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../config/authAxios";

const initialState = { loading: false, result: [], error: null, message: "" };

export const getAllAdminsAPI = createAsyncThunk(
  "get-all-admins",
  async (data, { rejectWithValue }) => {
    try {
      let response;
      if (data.type) {
        response = await Axios.get(
          `/admin?sort=${data.sort}&type=${data.type}`
        );
      } else response = await Axios.get(`/admin?sort=${data.sort}`);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "get-all-admins",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdminsAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllAdminsAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(getAllAdminsAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const getAllAdminsReducer = counterSlice.reducer;

export default getAllAdminsReducer;
