import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClbBidDetailsAPI } from "../../redux/clb/getClbBidDetails";
import moment from "moment";

const BidPriceDetailsModal = ({ open, setOpen, Clb }) => {
  const getClbBidDetailsSelector = useSelector(
    (state) => state?.getCLbBidDetails
  );
  const { result, loading } = getClbBidDetailsSelector;

  console.log("bidder Details", result);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClbBidDetailsAPI(Clb?._id));
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Bid Details: {Clb?.title}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            Loading...
          </Typography>
        ) : result?.length === 0 ? (
          <Typography sx={{ textAlign: "center" }}>
            No Bid Details Available
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Top Bidders</TableCell>
                  <TableCell>Price(inr)</TableCell>
                  <TableCell>Price(usd)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result?.map((row) => (
                  <TableRow key={row?._id}>
                    <TableCell>
                      {moment(row?.created_at).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{row?.user?.name}</TableCell>
                    <TableCell>{row?.price?.inr}</TableCell>
                    <TableCell>{row?.price?.usd?.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BidPriceDetailsModal;
