import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Style from "../../styles/logout.module.scss";

export default function Logout() {

    useEffect(() => { localStorage.clear() }, [])

    return (
        <>
            <Box className={Style.main}>
                <Card sx={{ w: '30%', borderRadius: 10 }}>
                    <Stack alignItems='center' spacing={2}>
                        <CheckCircleOutlineOutlinedIcon color='success' sx={{ fontSize: 100 }} />
                        <Typography variant='h6'>You have been logged Out</Typography>
                        <Stack alignItems='center'>
                            <Typography variant="p">We suggest you back to home</Typography>
                        </Stack>
                        <Link to='/login'><Button variant='contained' startIcon={<KeyboardBackspaceIcon />}  >Back to login</Button></Link>
                    </Stack>
                </Card>
            </Box>
        </>
    )
}
